/* eslint-disable */
import styled from 'styled-components'

export const ChartWrapper = styled.div`
  display: contents;
  img {
    display: block;
  }
`

export const InfoBox = styled.div<any>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-left: 1px solid #364ba0;
  padding: 6px;
  height: 25%;
  width: 100%;
  background-color: black;
  border-bottom: 1px solid #364ba0;
`

export const IbRow1 = styled.div`
  display: flex;
`

export const IbRow1Col1 = styled.div`
  font-size: 12px;
`

export const IbRow1Col2 = styled.div`
  text-align: right;
  margin-top: 4px;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IbRow2 = styled.div`
  display: flex;
  align-items: center;
`

export const IbRow2Col1 = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
`

export const IbRow2Col2 = styled.div`
    display: flex;
    flex-direction: column;
}
`
